export const enum MIME {
  CSV = 'text/csv',
  PDF = 'application/pdf',
}

export const enum INPUT_FILE_FORMATS {
  PNG = 'image/png',
  JPEG = 'image/jpeg',
  JPG = 'image/jpg',
  DOCX = '.docx',
  PDF = '.pdf',
  BMP = '.bmp',
  CSV = '.csv',
}
