import { type FC } from 'react';
import { Tabs } from 'destiny/dist/components/molecules/tabs';
import { MenuItem } from 'types';
import { trackMixpanel } from 'utils/mixpanel';
import TabItem from 'components/tabs-with-icons/TabItem';

type TabsWithIconsProps = {
  list: MenuItem[];
  onChange: (item?: MenuItem) => void;
  customSelectedIndex?: number;
  id: string;
};

const TabsWithIcons: FC<TabsWithIconsProps> = ({ list, onChange, customSelectedIndex, id }) => {
  return (
    <Tabs
      list={list}
      onSelect={onChange}
      customSelectedIndex={customSelectedIndex}
      id={id}
      eventCallback={trackMixpanel}
      TabItemComponent={TabItem}
      indicatorClassName=''
      wrapperClassName='tw-mb-6'
      tabItemWrapperStyle='!tw-w-full'
      selectedTabIndicatorClassName='tw-opacity-0 tw-w-0'
    />
  );
};

export default TabsWithIcons;
